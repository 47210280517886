
import React, { useRef, useState } from 'react';

import cn from 'classnames';
import Link from 'next/link';

import { useIsomorphicLayoutEffect } from '@lib/hooks'

import styles from './index.module.less'

interface ScrollProps {
  dataSource: Array<{
    url: string,
    title: string
  }>,
  containClass?: string,
  itemClass?: string,
}

const ItemLink = ({ url, title, className }) => {
  if (url) {
    return (
      <Link href={url}>
        <a target="_blank" data-buried="announcement" className={className}>{title}</a>
      </Link>
    )
  }
  return (
    <p className={className}>{title}</p>
  )
}

export const Scroll:React.FC<ScrollProps> = ({ dataSource, containClass, itemClass }) => {
  const [list, setList] = useState(dataSource)
  const dataRef = useRef(dataSource)
  const contentRef = useRef();
  const timeRef = useRef(null);

  useIsomorphicLayoutEffect(() => {
    if (dataSource.length < 2) {
      return
    }
    if (dataSource?.length && !dataRef.current?.length) {
      dataRef.current = dataSource;
      setList(dataSource)
    }
    timeRef.current = setInterval(() => {
      const firstData = dataRef.current.shift()
      setList([...dataRef.current, firstData])
      dataRef.current = [...dataRef.current, firstData]
    }, 5000)
    return () => {
      clearInterval(timeRef.current);
    }
  }, [dataSource])

  return (
      <div className={cn(styles.content, containClass)} ref={contentRef}>
      {list?.map((item, index) => {
        return (
          <ItemLink key={`${item?.title}_${index}`} {...item}
            className={cn(styles.item, itemClass, {
              [styles.itemAnm]: !index && list.length > 2
            })} />
        )
      })}
    </div>
  )
}
