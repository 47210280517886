import React, { useState } from 'react'
// 这个必须放在第一行，要不然会启动报错，目前未排查出问题
import { SubscribeUs } from './Component/SubscribeUs'

import { useAuthSession, useIsomorphicLayoutEffect } from '@lib/hooks'
import { getRemoteConfigValue } from '@utils/firebase'
import { helper } from '@utils/index'

import { FooterH5 } from './Component/FooterH5'
import { FooterPC } from './Component/FooterPC'
interface PageFooterProps {
  theme?: 'dark' | 'grey';
}

export const PageFooter: React.FC<PageFooterProps> = ({ theme }) => {
  const [newFooterData, setNewFooterData] = useState([])

  useIsomorphicLayoutEffect(() => {
    getRemoteConfigValue(`footer_nav_web_${helper.getLocaleKey()}`).then((res) => {
      try {
        setNewFooterData(JSON.parse(res.asString()))
      } catch (e) {
      }
    })
  }, [])
  return <>
    <FooterPC newFooterData={newFooterData} theme={theme} />
    <FooterH5 newFooterData={newFooterData} theme={theme} />
  </>
}

const FooterNav = () => {
  const isLogin = useAuthSession({ redirectTo: null })

  return (
    <>
      {!isLogin && (<SubscribeUs />)}
      <PageFooter theme="dark" />
    </>
  )
}
export default FooterNav
