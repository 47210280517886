import React, { useState } from 'react';

import { message } from 'antd';
import Image from 'next/image';
import Link from 'next/link';

import { openModal } from '@element/index';
import I18n from '@utils/i18n';

import styles from './index.module.css';

const disclaimerLink = {
  hk: 'https://kikitrade.zendesk.com/hc/zh-tw/articles/5369136501263',
  zh: 'https://kikitrade.zendesk.com/hc/zh-cn/articles/5369136501263',
  en: 'https://kikitrade.zendesk.com/hc/en-hk/articles/5369136501263'
}
let globalCheck = false;

const DisclaimerMarginContent = () => {
  const [checked, setChecked] = useState(false)
  return (
    <div className={styles.modalContent}>
        <div>
            <div>{I18n.t('lever_disclaimer_modal_content')}</div>
            <div className={styles.content}>
                <div className={styles.radioImage} onClick={() => {
                  globalCheck = !checked
                  setChecked(!checked)
                }}>
                    <Image
                        alt='kiki'
                        src={checked ? require('@images/radioSelected.webp') : require('@images/radio.webp')}
                        layout='responsive'
                    />
                </div>
                <div className={styles.link}>
                    <Link href={disclaimerLink[I18n.locale] || disclaimerLink.zh}>
                        <a target='_blank'>{I18n.t('lever_disclaimer')}</a>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export const disclaimerMargin = () => {
  return new Promise((resolve, reject) => {
    openModal({
      title: I18n.t('tip'),
      cancelText: I18n.t('common_cancel'),
      okText: I18n.t('ok'),
      children: DisclaimerMarginContent,
      onCancel: (close) => (e) => {
        close()
        resolve({ state: false })
      },
      onOk: (close) => (e) => {
        if (!globalCheck) {
          message.warning(I18n.t('lever_disclaimer'))
          return
        }
        globalCheck = false
        close()
        resolve({ state: true })
      }
    })
  })
}
