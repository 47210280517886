/**
 * @Author:      vincent
 * @DateTime:    2018-03-28 21:56:05
 */

'use strict';

import appUrl from '../projectapp/urlConfig';
const config = {
  websocket: appUrl.websocket,
  supportUrl: appUrl.api.supportUrl,
  api: {
    host: appUrl.api.host, // webnode api
    host_newapi: appUrl.api.host_newapi, // api
    host_api_v1: appUrl.api.host_api_v1, // api_v1
    upload_api: appUrl.api.upload_api,
    kiki_website_url: appUrl.api.kiki_website_url,
    share: appUrl.api.share,
    share_airdrop: appUrl.api.share_airdrop,
    get_short_link_url: appUrl.api.get_short_link_url,
    market: {
      coinList: 'v1/market/currencies',
      marketSymbols: 'v1/market/symbols',
      explore: 'v1/market/explores',
      trends: 'v1/market/trends',
      popularCurrencies: 'v1/market/currencies/popular'
    },
    merchant: {
      payment: {
        list: 'payment/channel/list',
        oftenUsedList: 'payment/oftenused/channel/list',
        detail: 'payment/channel/detail',
        add: 'payment/channel/add',
        modify: 'payment/channel/modify',
        updateStatus: 'payment/channel/status/update'
      },
      info: 'p2p/merchant/info',
      update: 'p2p/merchant/update',
      applyList: 'p2p/merchant/apply/list',
      applyCheck: 'v1/p2p/merchant/apply/check',
      apply: 'p2p/merchant/apply',
      applyDetail: 'p2p/merchant/apply/detail',
      kycDetail: 'kyc/result',
      adList: 'p2p/merchant/myads/list',
      adPost: 'p2p/merchant/ads/post',
      adCancel: 'p2p/merchant/ads/cancel',
      adOnline: 'p2p/merchant/ads/online'
    },
    account: {
      assetsList: 'v1/assets',
      assetsDetail: 'v1/assets/{coinCode}',
      getTodaysPNL: 'v1/assets/statistics/params', // 获取今日收益数据
      withdrawConfig: 'v1/pay/currencies/{coinCode}',
      withdrawAllConfig: 'v1/pay/currencies',
      info: 'account/info',
      assets: 'account/assets',
      balance: 'account/getVirtualAccount',
      history: 'account/getCoinHistory',
      coinList: 'v1/market/currencies',
      depositAddress: 'dm/deposit/inner/address',
      showDepositAddress: 'dm/deposit/inner/address/show',
      currency: 'currency', // +USDT
      transactionList: 'dm/transaction/list',
      transfer: 'account/transfer',
      withdrawFee: 'dm/withdraw/fees', // 该接口返回所有product信息，即可以忽略currency
      withdrawApply: 'dm/withdraw/apply',
      yuebaoProductList: 'yuebao/productList',
      yuebaoTransfer: 'yuebao/transfer',
      depositStatus: 'v1/pay/success/deposit/',
      assetOrderList: 'v1/pay/crypto/orders',
      payCurrenciesInfo: 'v1/pay/currencies/{currency}' // 获取单个出入金币种信息
    },
    accountV1: {
      transactionList: 'v1/pay/crypto/transactions',
      delWithdrawAddress: 'v1/wallet/addresses/{asset}/withdraw/{addressId}',
      withdrawAddress: 'v1/wallet/addresses/{asset}/withdraw',
      depositAddress: 'v1/wallet/addresses/{asset}',
      withdrawApply: 'v1/pay/crypto/withdraw/{asset}',
      showDepositAddress: 'v1/wallet/addresses/{asset}',
      payCurrenciesInfo: 'v1/pay/currencies/{currency}', // 获取单个出入金币种信息
      assetOrderList: 'v1/pay/crypto/orders' // 充提币历史
    },
    explore: {
      getLongShort: 'api/data/longshort',
      getList: 'api/data/list',
      getLiquidation: 'api/data/liquidation'
    },
    trade: {
      zoneList: 'v1/market/zones',
      currencyDetail: 'v1/market/currencies/{currency}',
      orderbooks: '/v1/market/orderbooks/{symbol}',
      order: 'v1/trading/orders',
      cancelOrder: 'v1/trading/orders/{orderId}',
      orderDetailFull: 'v1/trading/orders/{orderId}/detail',
      tradingTrades: '/v1/trading/trades',
      tradingInfo: 'v1/trading/info',
      depositStatus: 'v1/pay/success/deposit/',
      changeMargin: 'v1/trading/margin',
      marginInterests: '/v1/trading/margin/interests/transactions',
      marginSettleOrders: 'v1/trading/margin/settle/orders',
      marginBalance: 'v1/trading/balance',
      zoneDetail: 'v1/market/zones/{key}/{currency}',
      baseCurrencies: 'v1/market/currencies/baseCurrencies',
      acquireOrderId: 'v1/trading/order/id',
      assessment: 'v1/assessment',
      patchCancelOrder: 'v1/trading/orders'
    },
    earnings: {
      financingAccount: 'financing/account',
      financingProducts: 'financing/products',
      financingProductsAuto: 'financing/products/auto/currencies',
      financingAutoSubscribe: 'financing/auto/subscribe',
      financingProductsSubscribeDetail:
        'financing/products/{productId}/subscribe',
      financingProductsRedeemDetail: 'financing/products/{productId}/redeem',
      financingContracts: 'financing/contracts',
      fixedDepositRevoke: 'v1/fixed-deposit/logs/{id}',
      fixedDepositLogs: 'v1/fixed-deposit/logs',
      fixedDepositContracts: 'v1/fixed-deposit/contracts',
      fixedDepositContractDetail: 'v1/fixed-deposit/contracts/{id}',
      financingContractsHistory: 'financing/contracts/history',
      investmentProducts: 'investment/products',
      investmentProductsSubscribeDetail:
        'investment/products/{productId}/subscribe', // TODO: 提交参与定投
      investmentContracts: 'investment/contracts',
      investmentContractsRenameDetail:
        'investment/contracts/{contractId}/rename',
      investmentContractsHistoryDetail:
        'investment/contracts/{contractId}/history',
      investmentContractsHistory: 'investment/contracts/history',
      investmentContractsPauseDetail: 'investment/contracts/{contractId}/pause',
      investmentContractsResumeDetail:
        'investment/contracts/{contractId}/resume',
      investmentContractsStopDetail: 'investment/contracts/{contractId}/stop',
      investmentContractsAutoFinancingDetail:
        'investment/contracts/{contractId}/auto/financing',
      investmentContractsLogDetail: 'investment/contracts/log/{operateId}',
      financingProductsDetail: 'financing/products/{productId}'
    },
    earningsV1: {
      financingProductsAutoSubscribe: 'v1/financing/products/{productId}/auto/subscribe',
      financingContractsAutoSubscribe: 'v1/financing/contracts/{contractId}/auto/subscribing',
      investmentProductsRunning: 'v1/investment/products/{productId}/running',
      investmentContractsStop: 'v1/investment/contracts/{contractId}/stop',
      fixedDepositProductSubscribe: 'v1/fixed-deposit/products/{id}',
      fixedDepositRevoke: 'v1/fixed-deposit/logs/{id}',
      fixedDepositProducts: 'v1/fixed-deposit/products',
      fixedDepositProductDetail: 'v1/fixed-deposit/products/{id}',
      fixedDepositLogs: 'v1/fixed-deposit/logs',
      fixedDepositContracts: 'v1/fixed-deposit/contracts',
      fixedDepositContractDetail: 'v1/fixed-deposit/contracts/{id}'
    },
    social: {
      ossKey: 'api/social/assumeRole',
      hotTopic: 'api/social/topic/hot',
      hotUsers: 'api/social/users/hot',
      topicAll: 'api/social/topic/all',
      topicDetail: 'api/social/topic/{topicId}',
      topicPostFeed: 'api/social/topic/{topicId}/post',
      topicCategory: 'api/social/topic/category',
      storyRecommend: 'api/social/story/recommend',
      storySquare: 'api/social/story/square',
      nextPost: 'api/social/enrich/feed/post/story/next',
      updateLanguageZone: 'api/social/user/{id}/zone'
    },
    customer: {
      sendEmailCode: 'customer/verify/send/email/code',
      sendPhoneCode: 'phone/auth/code',
      bindPhone: 'phone/auth/binding',
      unBindPhone: 'phone/auth/remove',
      bindEmail: 'email/auth/binding',
      unBindEmail: 'email/auth/remove',
      verify: 'customer/verify',
      verifyGoogle: 'google/auth',
      register: 'customer/register', // 注册
      logout: 'customer/logout', // 登出
      login: 'customer/login',
      login_oauth: 'customer/login/oauth',
      forgotPwd: 'customer/pwd/forgot', //
      forgotPwdNew: 'customer/pwd/forgot/new',
      pwdReset: 'customer/pwd/reset',
      query: 'customer/querycustomer',
      device: 'v1/customer/device',
      rebateInfo: 'rebate/get/rebate/baseinfo',
      verifyRebateCode: 'rebate/verify/code', // 校验邀请码
      customer: 'customer', // 获取用户信息
      full: 'customer/full/verify',
      update: 'customer/extra/update', // 修改联系方式
      fullVerify: 'customer/full/verify',
      security: 'v1/customer/verify/security',
      ossKey: 'v1/customer/credential/token',
      assetPassword: 'v1/customer/password/asset',
      securityVerify: 'v1/customer/verify/security',
      assetPwdVerify: 'v1/customer/verify/password/asset'
    },
    google: {
      key: 'google/auth/key',
      binding: 'google/auth/binding',
      auth: 'google/auth',
      remove: 'google/auth/remove'
    },
    support: {
      sign: 'support/sign'
    },
    repay: {
      repayList: 'v1/trading/margin/repayments',
      repay: 'v1/trading/margin/repay',
      assets: 'v1/trading/margin/assets',
      preview: 'v1/trading/margin/repay/preview',
      repayments: 'v1/trading/margin/repayments/{repayment_id}'
    },
    revoke: {
      cryptoCancel: 'v1/pay/crypto/orders/{id}/cancel'
    },
    favoriteCustom: {
      favoriteRecommend: 'v1/market/favorites/recommend',
      hotSearch: 'v1/market/symbols/hot',
      favoriteAdd: 'v1/market/favorites',
      favoriteDelete: 'v1/market/favorites',
      favoriteSort: 'v1/market/favorites/sort',
      updateFavorite: 'v1/market/favorites/sequences',
      // favoriteZoneAdd: 'favorite/zone/add',
      // favoriteZoneDelete: 'favorite/zone/delete',
      // favoriteZoneSort: 'favorite/zone/sort',
      favoriteList: 'v1/market/favorites'
    },
    activity: {
      luckyBoxCount: '/cfd/activity/luckybox/remain',
      addLuckyBoxCount: '/cfd/activity/luckybox/addOneTime',
      openLuckyBox: '/cfd/activity/luckybox/open',
      getLuckyBoxResult: '/cfd/activity/luckybox/check',
      getLuckyBoxRecords: '/cfd/activity/luckybox/records/winning',
      redeem: 'redeemcard/redeem',
      history: 'redeemcard/history',
      redeemcardBuy: 'redeemcard/buy',
      buyHistory: 'redeemcard/buy/history',
      buyCards: 'redeemcard/buy/cards',
      referredRanking: 'cfd/activity/referred/ranking',
      inviteCode: 'customer/inviteCode',
      slotReferred: 'cfd/activity/slot/referred',
      giftRemarkCode: 'redeemcard/buy/remarkcode',
      invitaRecord: 'v1/customer/invitees',
      inviteAwardsWithRole: 'v1/activities/invite/rewards/{role}',
      inviteAwards: 'v1/activities/invite/rewards',
      inviteInfo: 'v1/activities/invite',
      updateInviteCode: 'v1/customer/invite/code',
      joinActivity: 'v1/activities/join',
      rewardHistory: 'v1/activities/rewards',
      rebateProportion: 'v1/member/levels'
    },
    kyc: {
      kycResult: 'v1/kyc/result',
      kycToken: 'kyc/token',
      kycLiveness: 'kyc/liveness',
      kycOcrUpload: 'kyc/ocr/upload',
      kycVipUpload: 'v1/kyc/l2/apply',
      kyc1ToCheck: 'v1/kyc/check',
      kyc2InfoUpload: 'v1/kyc/level2/apply',
      kycPayQuota: 'v1/pay/currencies/quota'
    },
    kol: {
      kolsByType: 'api/social/users/kol/{type}',
      kolBanner: 'api/social/banner/{type}',
      kolDetail: 'api/social/user/kol/{id}/info'
    },
    cfd: {
      offlineSymbols: 'v1/market/symbols/offline'
    },

    airdrop: {
      claimHistory: 'v1/airdrop/last/receive',
      openAirdrop: 'v1/airdrop/open',
      openAirdropCheck: 'v1/airdrop/open/check',
      simpleDetail: 'v1/airdrop/release/simple/{id}',
      sendDetail: 'v1/airdrop/release/{id}',
      claimDetail: 'v1/airdrop/receive/{id}/{receiveId}',
      sendRecord: 'v1/airdrop/release/history',
      claimRecord: 'v1/airdrop/receive/history',
      airdropRule: 'v1/airdrop/rule',
      airdropSend: 'v1/airdrop/release_drop',
      receiveAirdrop: 'v1/airdrop/receive/v2'
    },
    c2c: {
      cancelCount: 'p2p/order/cancel/count',
      merchantConfig: 'p2p/merchant/config',
      orderPlace: 'p2p/order/place',
      orderAppeal: 'p2p/order/appeal',
      payerConfirm: 'p2p/order/payer/confirm',
      payeeConfirm: 'p2p/order/payee/confirm',
      orderCancel: 'p2p/order/cancel',
      areaAdsList: 'v1/p2p/merchant/area/ads/list',
      adsDetail: 'p2p/merchant/ads/detail',
      orderList: 'p2p/order/list',
      orderDetail: 'p2p/order/detail',
      p2pOrderList: 'v1/p2p/order/asset',
      p2pOrderChat: 'v1/p2p/order/{id}/chat',
      p2pTransferList: 'v1/p2p/account/log',
      transfer: 'v1/p2p/transfer'
    },
    projects: {
      projectsCfgs: 'v1/market/trends/projects/cfgs',
      projectsTickers: 'v1/market/trends/projects/tickers/{projectId}',
      projectsKline: 'v1/market/trends/projects/{projectId}',
      projectsCfgsId: 'v1/market/trends/projects/cfgs/{projectId}'
    }
  }
};
module.exports = config;

export default config;
