import apiBeta from './api_beta';
import apiPre from './api_pre';
import apiPre1 from './api_pre1';
import apiPreview from './api_preview';
import apiProd from './api_prod';
import apiProdGreen from './api_prodgreen';

const env = process.env.NEXT_PUBLIC_API_ENV
const HOST = process.env.NEXT_PUBLIC_HOST
const HOST_NEWAPI = process.env.NEXT_PUBLIC_HOST_NEWAPI
const HOST_API_V1 = process.env.NEXT_PUBLIC_HOST_API_V1
const HOST_SHORT = process.env.NEXT_PUBLIC_HOST_SHORT

let api;
let websocket = {
  host: 'wss://wstest.kikitrade.com:8081',
  appCode: 'c022929d0e1f4b0d8eace9cb9934bf0d',
  stage: 'TEST'
};
switch (env) {
  case 'local':
  case 'dev':
    api = {
      host: HOST,
      host_newapi: HOST_NEWAPI,
      host_api_v1: HOST_API_V1,
      // upload_api: 'https://upload.kikitrade.com/',
      upload_api: 'https://upload.dipbit.xyz/',
      supportUrl:
        'https://cschat.antcloud.com.cn/index.htm?tntInstId=1Ab_9Aku&scene=SCE00941738', // https://cschat.antcloud.com.cn/index.htm?tntInstId=1Ab_9Aku&scene=SCE00529069'
      share: 'https://share.kikitrade.com/',
      // kiki_website_url: 'http://localhost:3000/new/locale/',
      kiki_website_url: 'https://m.dipbit.xyz/',
      get_short_link_url: HOST_SHORT,
      share_airdrop: ''
    };
    break;
  case 'beta':
    api = apiBeta;
    break;
  case 'beta1': {
    const api1Beta = { ...apiBeta };
    Object.keys(api1Beta).forEach((item, index) => {
      const _value = api1Beta[item].replace('webbeta', 'webbeta1');
      api1Beta[item] = _value;
    });
    api = api1Beta;
    break;
  }
  case 'beta2': {
    const api1Beta = { ...apiBeta };
    Object.keys(api1Beta).forEach((item, index) => {
      const _value = api1Beta[item].replace('webbeta', 'webbeta2');
      api1Beta[item] = _value;
    });
    api = api1Beta;
    break;
  }
  case 'preview':
    api = apiPreview;
    break;
  case 'pre':
    api = apiPre;
    websocket = {
      host: 'wss://ws.kikitrade.com',
      appCode: 'c022929d0e1f4b0d8eace9cb9934bf0d',
      stage: 'RELEASE'
    };
    break;
  case 'pre1':
    api = apiPre1;
    websocket = {
      host: 'wss://ws.kikitrade.com',
      appCode: 'c022929d0e1f4b0d8eace9cb9934bf0d',
      stage: 'RELEASE'
    };
    break;
  case 'prodgreen':
    api = apiProdGreen;
    websocket = {
      host: 'wss://ws.kikitrade.com',
      appCode: 'c022929d0e1f4b0d8eace9cb9934bf0d',
      stage: 'RELEASE'
    };

    break;
  case 'pro':
  case 'prod':
  default:
    api = apiProd;
    websocket = {
      host: 'wss://ws.kikitrade.com',
      appCode: 'c022929d0e1f4b0d8eace9cb9934bf0d',
      stage: 'RELEASE'
    };

    break;
}

module.exports = {
  api: api,
  websocket
};
