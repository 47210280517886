import React from 'react'

import cn from 'classnames';
import Link from 'next/link'

import Istyles from './index.module.css'
export const NavChild = ({ clildData, key }) => {
  return <div key={key}>
            {
                clildData.map(item => (
                    <Link href={item.url} key={item.id}>
                        <a data-buried="nav-child" target={'_blank'}>
                            <div className={cn(Istyles.navDetial, 'DINPro')} data-name='kiki-web-navDetial'>{item.text}</div>
                        </a>
                    </Link>
                ))
            }
        </div>
}
