import React from 'react'
import I18n from '@utils/i18n';
import cn from 'classnames';
import Image from 'next/image'
import StartUse from '@components/StartUse'
import Istyles from './index.module.css'
export const SubscribeUs = () => {
  return (
      <div className={Istyles.sunscribe}>
                <div>
                    <Image
                        src={require('@images/subscribeUs.webp')}
                        height={740}
                        alt={'kikitrade'}
                    />
                </div>
                <div className={Istyles.subscribeInfo}>
                    <div className={cn(Istyles.subscribeTitle, 'Bold')}>{I18n.t('E05IscWx')}</div>
                    <div className={Istyles.newInformation}>{I18n.t('NPcbpRr0')}</div>
                    <div className={Istyles.useSubscribe}>
                        <StartUse />
                    </div>
                </div>
            </div>
  );
}
