import React from 'react';

import { Input as AntdInput } from 'antd';
import cn from 'classnames';

import { Icon } from '@components/index';
import { THEME } from '@constants/index';
import I18n from '@utils/i18n';

export const renderClear = (showClear, theme) => {
  const isDark = theme === THEME.DARK
  return (
    showClear
      ? {
          clearIcon: (
          <div style={{ height: '24px' }}>
            <Icon name={isDark ? 'inputCloseDark' : 'inputClose'} className={cn('renderInputClear', {
              darkRenderInputClear: isDark
            })} />
          </div>)
        }
      : false
  )
}

export default function Input (props) {
  const { placeholder, showClear = false, className, myRef, theme } = props;
  return (
    <AntdInput
      {...props}
      className={cn('kiki-input', className, {
        'dark_kiki-input': theme === THEME.DARK
      })}
      placeholder={placeholder || I18n.t('pleaseInput')}
      allowClear={renderClear(showClear, theme)}
      ref={myRef}
    />
  )
}

export function InputTextArea (props) {
  const { placeholder, showClear = false, className, myRef } = props;
  return (
    <AntdInput.TextArea
      {...props}
      className={cn('kiki-input', className)}
      placeholder={placeholder || I18n.t('pleaseInput')}
      allowClear={renderClear(showClear)}
      ref={myRef}
    />
  )
}
