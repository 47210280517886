import React from 'react';
import ReactCodeInput, { ReactCodeInputProps } from 'react-verification-code-input';

import cn from 'classnames';
import omit from 'lodash/omit'

import { THEME } from '@constants/index';

interface CodeInputProps extends ReactCodeInputProps {
  theme?: Theme;
  status?: 'error';
}

const CodeInput: React.FC<CodeInputProps> = (props) => {
  return (
    <ReactCodeInput
      {...omit(props, ['className', 'theme', 'status'])}
      className={cn(props.className, 'kiki-code-input', {
        'dark_code-input': props.theme === THEME.DARK,
        'error_code-input': props.status === 'error'
      })}
    />
  )
}

export default CodeInput
