import { Input, Button } from '@components/Element'
import I18n from '@utils/i18n';
import cn from 'classnames'
import { useState } from 'react'
import { useRouter } from 'next/router';

const StartUse = ({ className }) => {
  const router = useRouter();
  const [value, setValue] = useState()

  const onChange = (e) => setValue(e.target.value)
  const onClick = () => {
    const registerParams = { value, type: '1' }
    if (isNaN(Number(value)) && value) {
      registerParams.type = '2'
    }
    sessionStorage.setItem('register_home', JSON.stringify(registerParams))
    router.push('/register')
  }

  return (
    <div className={cn('kiki-startUse-main', className)}>
          <Input placeholder={I18n.t('rXlAlwET')} onChange={onChange} />
          <Button onClick={onClick} data-buried="JaDqxo07">{I18n.t('JaDqxo07')}</Button>
    </div>
  );
}
export default StartUse
