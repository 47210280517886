export const WIX_WEBSIT_PATH = 'https://www.web.kikitrade.com';
export const SITE_MAP_URL = 'https://www.kikitrade.com';
// export const SITE_MAP_URL = 'https://kikitrade-preview.vercel.app';

// 资讯
export const NEWS = '/information';
export const NEWS_PATHS = {
  /** 快讯 */
  INDEX: `${NEWS}`,
  /** 要闻 */
  HIGHLIGHTS: `${NEWS}/highlights`,
  /** 详情 */
  POST: `${NEWS}/post`
};

// 邀请好友
export const INVITATION = '/invitation';
// 首页
export const HOME_PATH = '/';
// 入金
export const DEPOSIT = '/deposit';
// 钱包相关页面路径
export const WALLET = '/wallet';
export const WALLET_PATHS = {
  /** 充币 */
  DEPOSIT: `${WALLET}/deposit/[coin]`,
  /** 提币 */
  WITHDRAWAL: `${WALLET}/withdrawal/[coin]`,
  // 钱包账户总览
  ACCOUNT_OVERVIEW: `${WALLET}`,
  /** 交易账户总览 */
  TRADE_OVERVIEW: `${WALLET}/trade`,
  /** 理财账户 - 活期 */
  FINANCE_OVERVIEW: `${WALLET}/finance`,
  FINANCE_FLEXSAVING_DETAIL: `${WALLET}/finance/flexibleSavingsDetail`,
  /** 理财账户 - 定期  */
  FINANCE_REGULAR_BASIS: `${WALLET}/finance/regularBasis`,
  FINANCE_REGULAR_BASIS_DETAIL: `${WALLET}/finance/regularBasisDetail`,
  /** 理财账户 - 定投  */
  FINANCE_REGULAR_INVESTMENT: `${WALLET}/finance/regularInvestment`,
  /** c2c账户 */
  C2C_ACCOUNT: `${WALLET}/c2c`,
  /** c2c详情 */
  C2C_ACCOUNT_DETILA: `${WALLET}/c2c/detail`,
  /** 币种列表 */
  COIN_LIST: `${WALLET}/account/main`,
  /** 充币历史 */
  HISTORY_DEPOSIT: `${WALLET}/history/deposit`,
  /** 提币历史 */
  HISTORY_WITHDRAWAL: `${WALLET}/history/withdrawal`,
  /** 利息收取 */
  HISTORY_INTEREST: `${WALLET}/history/interest`,
  /** 清算记录 */
  HISTORY_LIQUIDATION: `${WALLET}/history/liquidation`,
  /** 还款记录 */
  HISTORY_REPAYMENT: `${WALLET}/history/repayment`,
  /** 奖励 */
  HISTORY_REWARD: `${WALLET}/history/reward`,
  /** 理财历史 - 活期 */
  HISTORY_FINANCE_FLEXSAVING: `${WALLET}/history/finance/flexibleSavings`,
  /** 理财历史 - 定期 */
  HISTORY_FINANCE_REGULAR_BASIS: `${WALLET}/history/finance/regularBasis`,
  /** 理财历史 - 定投 */
  HISTORY_FINANCE_REGULAR_INVESTMENT: `${WALLET}/history/finance/regularInvestment`,
  /** 加密货币资产详情 */
  CRYPTO: `${WALLET}/crypto/[coin]`,
  /** C2C 资产账户划转 */
  TRANSFER: `${WALLET}/transfer/[coin]`
};

// 用户中心相关页面路径
export const USER = '/user';
export const USER_PATHS = {
  /** 账户总览 2022/10/25 账户总览下线，原先跳转账户总览的统一跳转安全中心 */
  USER_OVERVIEW: `${USER}/safety`,
  /** 风险测评 */
  RISK: `${USER}/risk`,
  /** 安全中心 */
  SAFETY: `${USER}/safety`,
  /** 其他设置 */
  OTHER_SETTING: `${USER}/otherSettings`,
  /** 我的交易 */
  TRADE: `${USER}/trade`,
  /** 首次登录设置用户信息 */
  USERINFO: '/login/userInfo',
  /** 身份认证 */
  KYC: `${USER}/kyc`
};

// 市场相关页面路径
export const MARKET_PATHS = {
  MARKET: '/market',
  ZONES: '/market/zones',
  FIND: '/discover'
};
// 社区话题页面路径
export const COMMUNITY_PATHS = {
  COMMUNITY: '/community', // 社区主页
  TOPIC: '/community/topic/', // 话题页面
  USER_HOME: '/community/user/', // 用户主页
  POST_DETAIL: '/post/', // 帖子详情
  PUBLISH: '/publish/' // 发帖 发长文
};

// 交易相关页面路径
export const TRADE_PATHS = {
  TRADE: '/trade'
};

// 项目方相关页面路径
export const PROJECTS_PATHS = {
  PROJECTS: '/projects',
  PROJECT: '/projects/project'
};

// 空投
const AIRDROP = '/airdrop';
export const AIRDROP_PATHS = {
  /** 领取空投 */
  RECEIVE: AIRDROP,
  /** 空投详情 */
  DETAIL: `${AIRDROP}/detail/`,
  /** 空投历史 */
  HISTORY: `${AIRDROP}/history`,
  /** 我的空投 */
  ME: `${AIRDROP}/me`,
  /** 发送空投 */
  SEND: `${AIRDROP}/send`
}

// 不需要登录的相关页面路径
export const LOGIN_BEFORE_PATHS = {
  /** 登录 */
  LOGIN: '/login',
  /** 注册 */
  REGISTER: '/register',
  /** 注册 */
  REGISTER_VERIFY_CODE: '/register/verifyCode',
  /** 引导下载 */
  DOWNLOAD: '/download',
  /** 忘记密码 */
  FORGETPASS: '/forgetPass',
  /** 重置密码 */
  RESET: '/forgetPass/reset',
  /** 验证码 */
  VERIFY_CODE: '/forgetPass/verifyCode'
};
// 登录后不能直接返回的页面
export const LOGIN_NOT_BACK_PAGR = [
  LOGIN_BEFORE_PATHS.LOGIN,
  LOGIN_BEFORE_PATHS.REGISTER,
  // LOGIN_BEFORE_PATHS.DOWNLOAD,
  LOGIN_BEFORE_PATHS.FORGETPASS,
  LOGIN_BEFORE_PATHS.RESET,
  LOGIN_BEFORE_PATHS.VERIFY_CODE,
  LOGIN_BEFORE_PATHS.REGISTER_VERIFY_CODE
];

export const FINANCIAL = {
  AIP: '/financial/AIP', // 定投页面
  AUTO_BUY_DETAIL: '/financial/autoBuyDetail', // 参与定投
  MY_AUTO_BUY_DETAIL: '/financial/myAutoBuyDetail', // 我的定投明细
  HISTORY_DETAIL: '/financial/historyDetail', // 定投历史的明细
  REGULAR_AND_FLEXIBLE: '/financial/regularAndFlexible' // 定期活期
};

export const DARK_FOOTER_PAGE = ['/'];
export const INVITATION_FOOTER_PAGE = ['/invitation'];
export const NOT_FOOTER_PAGE = [
  ...LOGIN_NOT_BACK_PAGR,
  USER_PATHS.USERINFO,
  `${COMMUNITY_PATHS.PUBLISH}[type]`,
  COMMUNITY_PATHS.COMMUNITY,
  `${COMMUNITY_PATHS.TOPIC}[id]`,
  `${COMMUNITY_PATHS.USER_HOME}[id]`,
  `${COMMUNITY_PATHS.POST_DETAIL}[id]`, '/404',
  `${AIRDROP_PATHS.RECEIVE}/[id]`,
  `${AIRDROP_PATHS.DETAIL}[id]`,
  AIRDROP_PATHS.HISTORY,
  AIRDROP_PATHS.ME,
  AIRDROP_PATHS.SEND,
  `${COMMUNITY_PATHS.POST_DETAIL}[id]`,
  '/404',
  `${FINANCIAL.HISTORY_DETAIL}[id]`
];

export const APP_DOWNLOAD_PATH = 'https://kikitrade.page.link/vwrq'

export const DOWNLOAD_NOT_DISPLAY_ROUTER = [`${AIRDROP_PATHS.RECEIVE}/[id]`, `${TRADE_PATHS.TRADE}/[coinCode]`]
