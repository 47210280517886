// @flow
const api = {
  host: 'https://newapi.kikitrade.com/',
  host_newapi: 'https://api.kikitrade.com/',
  host_api_v1: 'https://api.kikitrade.com/',
  supportUrl: 'https://cschat.antcloud.com.cn/index.htm?tntInstId=1Ab_9Aku&scene=SCE00529069',
  share: 'https://share.kikitrade.com/',
  kiki_website_url: 'https://m.kikitrade.com/',
  get_short_link_url: 'https://kiki.trade'
};

export default api
